





























































































































































@use "sass:math";
@import '@design';

.toolbar .v-toolbar {
  z-index: 3;
}

.header {
  background-color: $color-navbar;
}
.nav-left,
.nav-right {
  flex-basis: 50%;
}

.nav-route {
  display: inline-block;
  margin: 0 math.div($size-grid-padding, 2);
  text-transform: uppercase;
  a {
    // TODO (ESS) : globalize fonts
    font-family: 'Karla', sans-serif;
    font-weight: bold;
    color: $color-bluegiant-blue;
    text-decoration: none;
    letter-spacing: 0.15em;
  }
  &.BaseButton a {
    color: $color-button-text;
  }
  &.social-icon {
    flex: 0 1 auto;
    img {
      transition: opacity 0.2s ease;
    }
    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }
  &.router-link-active a {
    padding-bottom: 0.4em;
    color: $color-bluegiant-light-blue;
    text-decoration: none;
    cursor: default;
    border-bottom: 2px solid $color-bluegiant-red;
  }
} // nav-route

.nav-logo--link {
  line-height: 0;
}
.nav-logo {
  max-width: 348px;
  cursor: pointer;
  &.medium {
    max-width: 180px;
  }
  &.small {
    max-width: 140px;
  }

  @media (max-width: 320px) {
    max-width: 100px !important;
  }
}

.toolbar {
  .v-toolbar__content {
    position: relative;

    $margin: 5px;

    &::after {
      position: absolute;
      top: $margin;
      left: $margin;
      z-index: 0;
      width: calc(100% - #{$margin * 2});
      height: calc(100% - #{$margin * 2});
      content: '';
      border: 3px solid $color-bluegiant-blue;
    }
    > * {
      z-index: 1;
    }
  }
}

@media only screen and (max-width: 414px) {
  .toolbar .v-toolbar__content {
    padding: 0 5px;
  }
}
